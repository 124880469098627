
import { defineComponent, ref, onMounted, Ref, onBeforeMount } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import subscriptionService from '../../../services/subscription'
import keyService from '../../../services/key'
import planService from '../../../services/plan'
import commonServices from '../../../services/common'
import router from './../../../router'

declare const Stripe
interface Value {
  key?: string;
  label?: string;
}
interface DataItem {
  _id?: string;
  limit_from: number;
  limit_to: number;
  price: number;
  plan?: string;
}
interface Plan {
  _id?: string;
  title: string;
  description: string;
  type: string;
  details?: [];
}
export default defineComponent({
  name: 'Subscription',
  components: {
    HomeOutlined
  },
  setup (props, { emit }) {
    const title = ref<string>(process.env.VUE_APP_PROJECT_NAME)
    const paymentKeys = ref()
    const subscription = ref()
    const stripe = ref()
    const errorMessage = ref<string>('')
    const subscriptionQty = ref<number>(1)
    const singleSubscription = ref<number>(0)
    const singleCredit = ref<number>(0)
    const subscriptionTotal = ref<number>(0)
    const creditTotal = ref<number>(0)
    const subscriptionType = ref<Value>({ key: 'month' })
    const creditQty = ref<number>(1)
    const creditType = ref<Value>({ key: 'month' })
    const dataSourceSub: Ref<DataItem[]> = ref([])
    const dataSourceCrd: Ref<DataItem[]> = ref([])
    const plans = ref<Array<Plan>>([])
    const hideBuyCredit = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const transactionSuccess = ref<boolean>(false)
    const card = ref()
    const elements = ref()
    const style = {
      base: {
        fontSize: '16px',
        color: '#32325d'
      }
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const activateClient = async (data) => {
      try {
        const clientId = commonServices.getClientId()
        await subscriptionService.createSubscription({
          subscription_id: data.id,
          subscription_name: 'Credit',
          type: 'Credit',
          customer_id: '',
          profile: clientId,
          amount: data.amount,
          available_credit: creditQty.value,
          total_credit: creditQty.value,
          quantity: creditQty.value
        })
        const responce = await subscriptionService.activateClient({
          id: data.id,
          profile_id: clientId,
          type: 'Credit',
          available_credit: creditQty.value,
          total_credit: creditQty.value,
          quantity: creditQty.value,
          amount: data.amount
        })
        localStorage.profile = JSON.stringify(responce.data.profile)
        localStorage.subscription = JSON.stringify(responce.data.subscription)
        router.push('/client/payment-success')
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const configureStripePayment = async () => {
      hideBuyCredit.value = true
      setTimeout(() => {
        const stripeKey = paymentKeys.value.filter(key => key.type === 'Stripe')
        if (stripeKey.length === 0) errorMessage.value = 'Stripe public key is not configured'
        stripe.value = Stripe(stripeKey[0].key)
        elements.value = stripe.value.elements()
        card.value = elements.value.create('card', { style })
        card.value.mount('#card-element')
      }, 100)
    }
    const generateStripeSecret = async () => {
      try {
        loading.value = true
        transactionSuccess.value = false
        errorMessage.value = ''
        const profile = commonServices.getCurrentProfile()
        const responce = await keyService.generateStripeSecretToken({ amount: creditTotal.value * 100, currency: 'GBP' })
        if (typeof responce.data === 'string') {
          errorMessage.value = responce.data
          loading.value = false
          return false
        }
        const result = await stripe.value.confirmCardPayment(responce.data.client_secret, {
          payment_method: {
            card: card.value,
            billing_details: {
              name: `${profile.first_name} ${profile.last_name}`,
              email: profile.email
            }
          }
        })
        console.log('result', result)
        loading.value = false
        if (result.error) {
          errorMessage.value = result.error.message
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            transactionSuccess.value = true
            activateClient(result.paymentIntent)
          }
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const subscriptionCheckout = async (sessionId) => {
      const stripeKey = paymentKeys.value.filter(key => key.type === 'Stripe')
      if (stripeKey.length === 0) errorMessage.value = 'Stripe public key is not configured'
      stripe.value = Stripe(stripeKey[0].key)
      localStorage.subscriptionType = 'Subscription'
      localStorage.subscriptionQuantity = subscriptionQty.value
      localStorage.subscriptionTotal = subscriptionTotal.value
      stripe.value.redirectToCheckout({ sessionId: sessionId })
    }
    const createCheckoutSession = async (priceId) => {
      try {
        const responce = await subscriptionService.createCheckoutSession({ priceId })
        console.log('responce', responce.data)
        subscriptionCheckout(responce.data.id)
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getGatewaySettings = async () => {
      try {
        const responce = await keyService.getKeys()
        paymentKeys.value = responce.data
        console.log('paymentKeys', paymentKeys.value)
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const calculatePrice = async (value, type) => {
      let price = 0
      let data
      if (type === 'subscription') data = dataSourceSub.value
      if (type === 'credit') data = dataSourceCrd.value
      for (const plan of data) {
        if (value >= plan.limit_from && value <= plan.limit_to) {
          price = plan.price
        }
      }
      if (type === 'subscription') singleSubscription.value = price
      if (type === 'credit') singleCredit.value = price
      return price
    }
    const subscriptionTypeChanged = async (value: Value) => {
      if (value.key === 'year') {
        subscriptionTotal.value = subscriptionTotal.value * 12
      } else {
        subscriptionTotal.value = subscriptionTotal.value / 12
      }
    }
    const creditTypeChanged = async (value: Value) => {
      if (value.key === 'year') {
        creditTotal.value = creditTotal.value * 12
      } else {
        creditTotal.value = creditTotal.value / 12
      }
    }
    const calculateSubscriptionAmount = async (value: number) => {
      const price = await calculatePrice(value, 'subscription')
      subscriptionTotal.value = +price * subscriptionQty.value
    }
    const calculateCreditAmount = async (value: number) => {
      const price = await calculatePrice(value, 'credit')
      creditTotal.value = +price * creditQty.value
    }
    const getClientPlans = async () => {
      try {
        const clientId = commonServices.getClientId()
        const responce = await planService.getClientPlans(clientId)
        plans.value = responce.data
        const subscription = plans.value.filter(plan => plan.type === 'Subscription')
        const credit = plans.value.filter(plan => plan.type === 'Credits')
        dataSourceSub.value = subscription[0].details
        dataSourceCrd.value = credit[0].details
        calculateCreditAmount(creditQty.value)
        calculateSubscriptionAmount(subscriptionQty.value)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const createStripeProduct = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        const clientId = commonServices.getClientId()
        const responce = await subscriptionService.createStripeProduct({
          subscription_name: profile.email + '_' + subscriptionTotal.value,
          amount: subscriptionTotal.value * 100,
          type: subscriptionType.value.key,
          profile: clientId,
          subscription_id: ''
        })
        console.log('responce', responce.data)
        createCheckoutSession(responce.data.price.id)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onBeforeMount(() => {
      emit('updateMenu')
    })
    onMounted(() => {
      subscription.value = commonServices.getCurrentSubscription()
      getGatewaySettings()
      getClientPlans()
    })
    return {
      createCheckoutSession,
      subscriptionTypeChanged,
      calculateSubscriptionAmount,
      calculateCreditAmount,
      creditTypeChanged,
      subscriptionQty,
      subscriptionType,
      creditQty,
      creditType,
      subscriptionTotal,
      creditTotal,
      singleSubscription,
      singleCredit,
      createStripeProduct,
      configureStripePayment,
      hideBuyCredit,
      generateStripeSecret,
      loading,
      subscription,
      title
    }
  }
})
