import axios from 'axios'

export default {
  getKeys () {
    return axios.get(process.env.VUE_APP_PAYMENT_GATEWAY_SETTINGS)
  },

  updateKey (id, data) {
    return axios.put(process.env.VUE_APP_PAYMENT_GATEWAY_SETTINGS + '/' + id, data)
  },

  generateStripeSecretToken (data) {
    return axios.post(process.env.VUE_APP_PAYMENT_GATEWAY_SETTINGS + '/stripe-secret', data)
  }
}
