import axios from 'axios'

export default {
  createCheckoutSession (data) {
    return axios.post(process.env.VUE_APP_SUBSCRIPTION + '/create-checkout-session', data)
  },

  createStripeProduct (data) {
    return axios.post(process.env.VUE_APP_SUBSCRIPTION + '/create-product', data)
  },

  activateClient (data) {
    return axios.post(process.env.VUE_APP_SUBSCRIPTION + '/activate-client', data)
  },

  createSubscription (data) {
    return axios.post(process.env.VUE_APP_SUBSCRIPTION, data)
  },

  getSubscribedCourses (id) {
    return axios.get(process.env.VUE_APP_SUBSCRIPTION + '/subscribed-course/' + id)
  },

  updateSubscription (id, data) {
    return axios.put(process.env.VUE_APP_SUBSCRIPTION + '/' + id, data)
  },

  addOfflineSubscription (data) {
    return axios.post(process.env.VUE_APP_SUBSCRIPTION + '/add-offline-subscription', data)
  }
}
