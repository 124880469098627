<template>
  <div class="main">
    <div class="payment-sample content-body">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Subscription
        </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="body">
        <div class="current-plan" v-if="subscription">
          <span v-if="subscription.type == 'Subscription'">Your current plan type is Subscription. You have {{subscription.available_credit}} subscriptions out of {{subscription.quantity}}.</span>
          <span v-if="subscription.type == 'Credit'">Your current plan type is Credit. You have {{subscription.available_credit}} credit out of {{subscription.quantity}}.</span>
        </div>
        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="payment-type">
          <div class="subscribe leftSub">
            <div class="header">Subscription</div>
            <div class="subtype">Business Subscription</div>
            <div class="content">
             The {{title}} Business Subscription gives you access to unlimited subscription for all your employees. All your employees can have access to all our courses via our platform at a low monthly fee. Our business subscription gives you unlimited access at an affordable price. The included LMS gives you complete control over the course content and training outcome. New courses are updated and added at no extra cost.
            </div>
            <div class="pRow">
            <div class="lefCnt">
             <div class="price">£ {{subscriptionTotal.toFixed(2)}} <span class="quantity">(£ {{singleSubscription}} x {{subscriptionQty}} Units )</span></div>
            <div class="user-action">
              <div class="amount">
                <a-input-number size="large" :min="1" :max="100000" v-model:value="subscriptionQty" @change="calculateSubscriptionAmount" :disabled="subscription && subscription.type =='Credit'" />
              </div>
              <div class="type">
                <a-select size="large" label-in-value v-model:value="subscriptionType"  @change="subscriptionTypeChanged" :disabled="subscription && subscription.type =='Credit'">
                  <a-select-option value="month">Monthly</a-select-option>
                  <a-select-option value="year">Yearly</a-select-option>
                </a-select>
              </div>
            </div>
            <a-button type="primary" size="large" block class="subscribe-btn" html-type="submit" @click="createStripeProduct()" :disabled="subscription && subscription.type =='Credit'">
              Subscribe
            </a-button>
            </div>
            <div class="rightCnt">
               <img src="../../../assets/images/sub1.svg" />
            </div>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="payment-type">
          <div class="subscribe rightSub">
            <div class="header">Credits</div>
            <div class="subtype">Business Credits</div>
            <div class="content">
              With {{title}} Business Credits you can buy credits to access only the courses your team requires. This is ideal for companies who would like to select a few courses as and when required. 1 credit equals one course. Each credit can be redeemed for one complete course of self-paced eLearning.
            </div>
            <div class="pRow">
            <div class="lefCnt">
             <div class="price">£ {{creditTotal.toFixed(2)}} <span class="quantity">(£ {{singleCredit}} x {{creditQty}} Units )</span></div>
            <div class="user-action">
              <div class="amount">
                <a-input-number size="large" :min="1" :max="100000" v-model:value="creditQty" @change="calculateCreditAmount" :disabled="subscription && subscription.type =='Subscription'" />
              </div>
              <!--<div class="type">
                <a-select size="large" label-in-value v-model:value="creditType" style="width: 150px" @change="creditTypeChanged">
                  <a-select-option value="month">Monthly</a-select-option>
                  <a-select-option value="year">Yearly</a-select-option>
                </a-select>
              </div>-->
            </div>
            <a-button type="primary" size="large" block class="subscribe-btn" html-type="submit" @click="configureStripePayment()" v-if="!hideBuyCredit" :disabled="subscription && subscription.type =='Subscription'">
              Buy Credit
            </a-button>
            </div>
             <div class="rightCnt">
               <img src="../../../assets/images/sub2.svg" />
            </div>
            </div>
            <a-form ref="stripePaymentFormRef" id="payment-form" v-if="hideBuyCredit">
              <div class="form-row">
                <div id="card-element">
                  <!-- A Stripe Element will be inserted here. -->
                </div>
                <!-- Used to display Element errors. -->
                <div id="card-errors" role="alert"></div>
              </div>

              <a-button type="primary" size="large" block class="subscribe-btn" @click="generateStripeSecret" :loading="loading">Pay</a-button>
            </a-form>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, Ref, onBeforeMount } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import subscriptionService from '../../../services/subscription'
import keyService from '../../../services/key'
import planService from '../../../services/plan'
import commonServices from '../../../services/common'
import router from './../../../router'

declare const Stripe
interface Value {
  key?: string;
  label?: string;
}
interface DataItem {
  _id?: string;
  limit_from: number;
  limit_to: number;
  price: number;
  plan?: string;
}
interface Plan {
  _id?: string;
  title: string;
  description: string;
  type: string;
  details?: [];
}
export default defineComponent({
  name: 'Subscription',
  components: {
    HomeOutlined
  },
  setup (props, { emit }) {
    const title = ref<string>(process.env.VUE_APP_PROJECT_NAME)
    const paymentKeys = ref()
    const subscription = ref()
    const stripe = ref()
    const errorMessage = ref<string>('')
    const subscriptionQty = ref<number>(1)
    const singleSubscription = ref<number>(0)
    const singleCredit = ref<number>(0)
    const subscriptionTotal = ref<number>(0)
    const creditTotal = ref<number>(0)
    const subscriptionType = ref<Value>({ key: 'month' })
    const creditQty = ref<number>(1)
    const creditType = ref<Value>({ key: 'month' })
    const dataSourceSub: Ref<DataItem[]> = ref([])
    const dataSourceCrd: Ref<DataItem[]> = ref([])
    const plans = ref<Array<Plan>>([])
    const hideBuyCredit = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const transactionSuccess = ref<boolean>(false)
    const card = ref()
    const elements = ref()
    const style = {
      base: {
        fontSize: '16px',
        color: '#32325d'
      }
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const activateClient = async (data) => {
      try {
        const clientId = commonServices.getClientId()
        await subscriptionService.createSubscription({
          subscription_id: data.id,
          subscription_name: 'Credit',
          type: 'Credit',
          customer_id: '',
          profile: clientId,
          amount: data.amount,
          available_credit: creditQty.value,
          total_credit: creditQty.value,
          quantity: creditQty.value
        })
        const responce = await subscriptionService.activateClient({
          id: data.id,
          profile_id: clientId,
          type: 'Credit',
          available_credit: creditQty.value,
          total_credit: creditQty.value,
          quantity: creditQty.value,
          amount: data.amount
        })
        localStorage.profile = JSON.stringify(responce.data.profile)
        localStorage.subscription = JSON.stringify(responce.data.subscription)
        router.push('/client/payment-success')
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const configureStripePayment = async () => {
      hideBuyCredit.value = true
      setTimeout(() => {
        const stripeKey = paymentKeys.value.filter(key => key.type === 'Stripe')
        if (stripeKey.length === 0) errorMessage.value = 'Stripe public key is not configured'
        stripe.value = Stripe(stripeKey[0].key)
        elements.value = stripe.value.elements()
        card.value = elements.value.create('card', { style })
        card.value.mount('#card-element')
      }, 100)
    }
    const generateStripeSecret = async () => {
      try {
        loading.value = true
        transactionSuccess.value = false
        errorMessage.value = ''
        const profile = commonServices.getCurrentProfile()
        const responce = await keyService.generateStripeSecretToken({ amount: creditTotal.value * 100, currency: 'GBP' })
        if (typeof responce.data === 'string') {
          errorMessage.value = responce.data
          loading.value = false
          return false
        }
        const result = await stripe.value.confirmCardPayment(responce.data.client_secret, {
          payment_method: {
            card: card.value,
            billing_details: {
              name: `${profile.first_name} ${profile.last_name}`,
              email: profile.email
            }
          }
        })
        console.log('result', result)
        loading.value = false
        if (result.error) {
          errorMessage.value = result.error.message
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            transactionSuccess.value = true
            activateClient(result.paymentIntent)
          }
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const subscriptionCheckout = async (sessionId) => {
      const stripeKey = paymentKeys.value.filter(key => key.type === 'Stripe')
      if (stripeKey.length === 0) errorMessage.value = 'Stripe public key is not configured'
      stripe.value = Stripe(stripeKey[0].key)
      localStorage.subscriptionType = 'Subscription'
      localStorage.subscriptionQuantity = subscriptionQty.value
      localStorage.subscriptionTotal = subscriptionTotal.value
      stripe.value.redirectToCheckout({ sessionId: sessionId })
    }
    const createCheckoutSession = async (priceId) => {
      try {
        const responce = await subscriptionService.createCheckoutSession({ priceId })
        console.log('responce', responce.data)
        subscriptionCheckout(responce.data.id)
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const getGatewaySettings = async () => {
      try {
        const responce = await keyService.getKeys()
        paymentKeys.value = responce.data
        console.log('paymentKeys', paymentKeys.value)
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const calculatePrice = async (value, type) => {
      let price = 0
      let data
      if (type === 'subscription') data = dataSourceSub.value
      if (type === 'credit') data = dataSourceCrd.value
      for (const plan of data) {
        if (value >= plan.limit_from && value <= plan.limit_to) {
          price = plan.price
        }
      }
      if (type === 'subscription') singleSubscription.value = price
      if (type === 'credit') singleCredit.value = price
      return price
    }
    const subscriptionTypeChanged = async (value: Value) => {
      if (value.key === 'year') {
        subscriptionTotal.value = subscriptionTotal.value * 12
      } else {
        subscriptionTotal.value = subscriptionTotal.value / 12
      }
    }
    const creditTypeChanged = async (value: Value) => {
      if (value.key === 'year') {
        creditTotal.value = creditTotal.value * 12
      } else {
        creditTotal.value = creditTotal.value / 12
      }
    }
    const calculateSubscriptionAmount = async (value: number) => {
      const price = await calculatePrice(value, 'subscription')
      subscriptionTotal.value = +price * subscriptionQty.value
    }
    const calculateCreditAmount = async (value: number) => {
      const price = await calculatePrice(value, 'credit')
      creditTotal.value = +price * creditQty.value
    }
    const getClientPlans = async () => {
      try {
        const clientId = commonServices.getClientId()
        const responce = await planService.getClientPlans(clientId)
        plans.value = responce.data
        const subscription = plans.value.filter(plan => plan.type === 'Subscription')
        const credit = plans.value.filter(plan => plan.type === 'Credits')
        dataSourceSub.value = subscription[0].details
        dataSourceCrd.value = credit[0].details
        calculateCreditAmount(creditQty.value)
        calculateSubscriptionAmount(subscriptionQty.value)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const createStripeProduct = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        const clientId = commonServices.getClientId()
        const responce = await subscriptionService.createStripeProduct({
          subscription_name: profile.email + '_' + subscriptionTotal.value,
          amount: subscriptionTotal.value * 100,
          type: subscriptionType.value.key,
          profile: clientId,
          subscription_id: ''
        })
        console.log('responce', responce.data)
        createCheckoutSession(responce.data.price.id)
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onBeforeMount(() => {
      emit('updateMenu')
    })
    onMounted(() => {
      subscription.value = commonServices.getCurrentSubscription()
      getGatewaySettings()
      getClientPlans()
    })
    return {
      createCheckoutSession,
      subscriptionTypeChanged,
      calculateSubscriptionAmount,
      calculateCreditAmount,
      creditTypeChanged,
      subscriptionQty,
      subscriptionType,
      creditQty,
      creditType,
      subscriptionTotal,
      creditTotal,
      singleSubscription,
      singleCredit,
      createStripeProduct,
      configureStripePayment,
      hideBuyCredit,
      generateStripeSecret,
      loading,
      subscription,
      title
    }
  }
})
</script>
<style lang="scss">
.payment-sample {
  .body {
    background: #ffffff;
    margin-top: 20px;
    border-radius: 10px;
    padding: 15px 0 0 0;
    .current-plan {
      width: 100%;
      text-align: left;
      padding: 20px;
      margin-left: 30px;
      font-size: 20px;
    }
    .payment-type {
      padding: 15px;
      min-height: 200px;

      .subscribe {
        margin: 20px 35px;
        background-color: #f7fafc;
        min-height: 100px;
        padding: 20px;
        border-radius: 30px;
        text-align: left;
        box-shadow: 0px 16px 40px #b8b8b880;
        background: #7188f5;
        background: -webkit-linear-gradient(top, #7188f5, #2f4ee4);
        background: -moz-linear-gradient(top, #7188f5, #2f4ee4);
        background: linear-gradient(to bottom, #7188f5, #2f4ee4);
        .header {
          text-align: center;
          background-color: #294ae2;
          color: #fff;
          font-size: 18px;
          width: 200px;
          line-height: 45px;
          height: 46px;
          box-shadow: 0px 16px 40px #4b4bcc;
          border-radius: 39px;
          margin: -40px auto 0;
        }
        .price {
          color: #fff;
          font-weight: bold;
          font-size: 30px;
        }
        .quantity {
          font-weight: 100;
          font-size: 14px;
          color: #fff;
        }
        .subtype {
          color: #fff;
          padding: 15px 0 10px;
          font-size: 22px;
        }
        .content {
          color: #fff;
          line-height: 25px;
          font-size: 14px;
          padding: 0 0 10px 0;
          border-bottom: 1px solid #677ff3;
        }
        .user-action {
          display: flex;
          flex-direction: row;
          margin: 10px 0;
          .amount {
            margin-right: 10px;
            .ant-input-number-lg {
              border-radius: 8px;
              width: 70px;
              input {
                height: 32px;
                color: #4a4a4a;
                font-size: 14px;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              }
            }
          }
          .type {
            .ant-select-selector {
              border-radius: 8px;
              height: 32px;
              .ant-select-selection-item {
                line-height: 30px;
                color: #4a4a4a;
                font-size: 14px;
              }
            }
          }
        }
        .subscribe-btn {
          background-color: #ff8077;
          border-color: #ff8077;
          border-radius: 7px;
          margin-top: 10px;
          width: 80%;
          max-width: 180px;
        }
        .pRow {
          display: flex;
          padding: 20px 0 0 0;
          .lefCnt {
            width: 65%;
          }
          .rightCnt {
            width: 35%;
            display: flex;
            align-items: flex-end;
            img {
              width: 100%;
              max-width: 150px;
              display: block;
            }
          }
        }
        &.rightSub {
          background: #8383ff;
          background: -webkit-linear-gradient(top, #8383ff, #6d6dff);
          background: -moz-linear-gradient(top, #8383ff, #6d6dff);
          background: linear-gradient(to bottom, #8383ff, #6d6dff);
          .header {
            background-color: #4b4bcc;
          }
          .content {
            border-bottom: 1px solid #a1a1f7;
          }
          .subscribe-btn {
            background-color: #ff9835;
            border-color: #ff9835;
          }
        }
      }
      #card-element {
        border: 1px solid #e3e8ee;
        height: 44px;
        border-radius: 4px;
        padding: 12px;
        margin-bottom: 20px;
        background: #ffffff;
      }
    }
  }
}

</style>
