import axios from 'axios'

export default {
  getPlans () {
    return axios.get(process.env.VUE_APP_PLANS)
  },

  getClientPlans (clientId) {
    return axios.get(process.env.VUE_APP_PLANS + '/client/' + clientId)
  },

  addPlanDetails (data) {
    return axios.post(process.env.VUE_APP_PLAN_DETAILS, data)
  },

  updatePlanDetails (id, data) {
    return axios.put(process.env.VUE_APP_PLAN_DETAILS + '/' + id, data)
  },

  deletePlanDetails (id) {
    return axios.delete(process.env.VUE_APP_PLAN_DETAILS + '/' + id)
  },

  addClientPlanDetails (data) {
    return axios.post(process.env.VUE_APP_CLIENT_PLAN_DETAILS, data)
  },

  updateClientPlanDetails (id, data) {
    return axios.put(process.env.VUE_APP_CLIENT_PLAN_DETAILS + '/' + id, data)
  },

  deleteClientPlanDetails (id) {
    return axios.delete(process.env.VUE_APP_CLIENT_PLAN_DETAILS + '/' + id)
  }
}
